<template>
  <div class="register">
    <div v-title="'修改账号信息'"></div>
    <div class="register_header">
      <img :src="require('../assets/change.png')" alt="修改信息" />
    </div>
    <div class="tip">提示：要与皖事通的账号一致</div>
    <el-form
      :model="registerForm"
      :rules="rules"
      ref="registerForm"
      label-width="120px"
    >
      <el-form-item label="姓名:" prop="username">
        <el-input
          ref="input"
          v-model="registerForm.username"
          placeholder="请填写姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码:" prop="phone">
        <el-input
          v-model="registerForm.phone"
          placeholder="请填写手机号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="公司:">
        <el-input
          v-model="registerForm.company"
          placeholder="请填写公司/快递公司"
        ></el-input>
      </el-form-item>
      <div class="btn">
        <el-button type="primary" @click="submitForm('registerForm')"
          >确认修改</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {
      timer: null,
      registerForm: {
        username: "",
        phone: "",
        company: "",
      },
      rules: {
        username: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请填写手机号码", trigger: "blur" },
          {
            pattern: /^[1-9]{1}\d{10}$/g,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //修改成功都要重新做一遍登录,的到新的token和手机号
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.registerForm.oldPhone = sessionStorage.getItem("phone");
          this.$axios
            .post("/cuser/updateInfo", this.registerForm)
            .then((res) => {
              if (res.code === 0) {
                sessionStorage.setItem("phone", this.registerForm.phone);
                this.$weui.toast(res.msg, 2000);
                this.$router.push({
                  path: "/",
                  query: {
                    phone: this.registerForm.phone,
                  },
                });
              } else {
                this.$weui.alert(res.msg, function () {
                  window.location.reload();
                });
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.register {
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  .register_header {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 80%;
    }
  }
  .tip {
    padding: 10px 0;
    height: 25px;
    background-color: #e4e9f6;
  }
  .el-form {
    background-color: #fff;
    .el-form-item {
      padding: 15px 0;
      margin: 0;
      border-bottom: 1px solid #f5f5f5;
    }
    .el-form-item /deep/ .el-input__inner {
      border: none;
    }
    .btn {
      text-align: center;
      background-color: #f5f5f5;
      padding-top: 30px;
    }
  }
}
</style>
